import React from "react";
import AppLayout from "./layout";
import "../styles/process.css";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { useHistory } from "react-router-dom";

export default function CloseDoor() {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [expired, setExpired] = React.useState("");

  const changeToHome = () => {
    history.push("/home");
  };
  React.useEffect(() => {
    setTimeout(() => {
      let _expired = {
        pt: "Poderá consultar o seu recibo assim que concluída a transação.",
        en:
          "You will be able to consult your receipt as soon as it is completed.",
      };
      setTimeout(() => {
        changeToHome();
      }, 7500);
      setExpired(_expired[locale]);
    }, 3000);
  }, [expired]);

  return (
    <AppLayout
      title={""}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="payment-loader" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column"
      }}>
      <img
          src={process.env.PUBLIC_URL + "/gif3.gif"}
          alt="Open Cabinet"
          style={{ width: "70%", margin: "auto" }}
        />
        <img
          src={process.env.PUBLIC_URL + "/obrigado.svg"}
          alt="Open Cabinet"
          style={{ width: "70%", margin: "auto",marginTop:"10px" }}
        />
        <div
          className="loader-text"
        >
          <p style={{color:"#A4BF97",fontWeight:"500"}}>O seu pagamento está a ser processado e em breve pode consultar o seu cesto.</p>
        </div>
      </div>
    </AppLayout>
  );
}
