import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import BuyByeService from "../services/buybye-service";
import Loader from "../pages/loader";
import moment from "moment";
import WorkOffIcon from "@material-ui/icons/WorkOff";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";

const useStyles = makeStyles((theme) => ({
  cardNumber: {
    marginBottom: "0px",
  },
  creditCard: {
    borderBottom: "1px solid #eee",
    display: "flex",
    paddingBottom: "1em",
    paddingTop: "1em",
    justifyContent: "space-around",
    cursor: "pointer",
  },
  icon: {
    display: "inline-block",
    marginRight: "1em",
    width: "45px",
    height: "45px",
    marginBlockStart: "1em",
  },
  trashIcon: {
    marginBlockStart: "1em",
    color: theme.palette.red,
    fontSize: "2em",
  },
  creditcards: {
    paddingBottom: "11%",
  },
  paymentHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  floatContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20%",
    marginTop: "1em",
  },
  center: {
    alignSelf: "center",
  },
}));
const NoCards = ({ ...props }) => {
  return <Typography>{Languages[props.locale].casesListNoItens}</Typography>;
};
const RecieptsCard = ({ ...props }) => {
  const classes = useStyles();
  console.log(props.locale);
  return (
    <div
      className={classes.creditCard}
      onClick={props.caseClick(props.data._id)}
    >
      <WorkOffIcon
        color="primary"
        style={{ margin: "0 0.5em" }}
        className={classes.center}
      />
      <div>
        <Typography className={clsx(classes.mt1)} color="primary">
          {Languages[props.locale].casesListItensCase} : {props.data.serial}
        </Typography>
        <Typography className={clsx(classes.mt1)} color="primary">
          {Languages[props.locale].casesListItensMachine} :{" "}
          {props.data.machine.code}
        </Typography>

        <Typography
          className={[clsx(classes.mt1), clsx(classes.center)].join(" ")}
          color="primary"
        >
          {props.data.subject}
        </Typography>
        <Typography className={clsx(classes.mt1)} color="primary">
          {Languages[props.locale].casesListItensStatus} :{" "}
          {Languages[props.locale].casesListItensStatusTrad[props.data.status]}
        </Typography>
      </div>

      <Typography
        className={[clsx(classes.mt1), clsx(classes.center)].join(" ")}
      >
        {moment(props.data.createdAt).format("DD/MM/YYYY")}
      </Typography>

      <div
        className={[clsx(classes.mt1), clsx(classes.center)].join(" ")}
        style={{ display: "flex", alignContent: "center" }}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
};
export default function CaseList() {
  const history = useHistory();
  const classes = useStyles();
  const [cases, setCases] = React.useState(null);
  const { locale } = React.useContext(LocaleContext);

  var backButtonPrevented = true;

  function popStateListener(event) {
    if (backButtonPrevented === false) {
      // Nope, go back to your page
      history.go(1);
      //backButtonPrevented = true;
    } else {
      window.removeEventListener("popstate", popStateListener);
    }
  }

  window.addEventListener("popstate", popStateListener);
  React.useEffect(() => {
    async function main() {
      let res = await BuyByeService.getUserCases();
      if (res.success) {
        console.log(res);
        setCases(res.result);
      }
    }
    main();
  }, [history]);

  const onClick = (e) => {
    console.log(e);
    history.push("/case-management");
    return;
  };

  const caseClick = (id) => (e) => {
    history.push("/case-management/" + id);
    return;
  };

  if (!cases) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return (
    <div className={clsx()}>
      <div className={clsx(classes.paymentHeader)}>
        <h1 className={"header__address"}>
          {Languages[locale].casesListLabel}
        </h1>
      </div>
      <div>
        {cases.length == 0 && <NoCards locale={locale} />}

        {cases.map((c) => (
          <RecieptsCard
            key={Math.floor(Math.random() * (125 - 5) + 5)}
            id={Math.floor(Math.random() * (125 - 5) + 5)}
            data={c}
            caseClick={caseClick}
            locale={locale}
          />
        ))}
      </div>

      <div className={classes.floatContainer}>
        <Fab
          color="primary"
          aria-label="add"
          style={{ color: "white" }}
          onClick={onClick}
        >
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
}
